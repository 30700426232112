<template>
  <div class="take-total">
    <el-card>
      <div slot="header" class="clearfix picker-box">
        <span>持仓汇总</span>
        <el-date-picker
          v-model="value"
          align="right"
          type="date"
          placeholder="选择日期"
          class="date-picker"
          @change="dateChange"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="0" label="交易日期" width="110"></el-table-column>
        <el-table-column prop="1" label="合约编号" width="110"></el-table-column>
        <el-table-column prop="2" label="买持仓" width="110"></el-table-column>
        <el-table-column prop="3" label="买均价" width="110"></el-table-column>
        <el-table-column prop="4" label="卖持仓" width="110"></el-table-column>
        <el-table-column prop="5" label="卖均价" width="110"></el-table-column>
        <el-table-column prop="6" label="昨结算价" width="110">
        </el-table-column>
        <el-table-column prop="7" label="今结算价" width="120">
        </el-table-column>
        <el-table-column prop="8" label="浮动盈亏" width="110">
        </el-table-column>
        <el-table-column prop="9" label="交易保证金" width="110">
        </el-table-column>
        <el-table-column prop="10" label="投机/套保" width="110">
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next, sizes"
        @size-change="sizeChange"
        @current-change="loadData"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="10"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import {Loading} from "element-ui";
import {apiTakeTotal} from "../../utils/api";

export default {
  name: "daily-sett",
  data() {
    return {
      size: 10,
      page: 1,
      total: 0,
      date: "",
      tableData: [],
      value: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  methods: {
    sizeChange(size) {
      this.size = size;
      this.loadData(this.page);
    },
    loadData(page) {
      if (this.$store.state.curAccountId.length === 0) {
        return false;
      }
      this.page = page;
      let accountId = JSON.stringify(this.$store.state.curAccountId);
      let loadingInstance = Loading.service({target: ".el-table"});
      apiTakeTotal({
        account: accountId,
        page: page,
        "per-page": this.size,
        trade_date: this.date,
      }).then((response) => {
        this.total = response.data.count;
        this.tableData = response.data.data;
        loadingInstance.close();
      });
    },
    dateChange(picker) {
      if (picker) {
        let Y = picker.getFullYear().toString();
        let M = (picker.getMonth() + 1 < 10
            ? "0" + (picker.getMonth() + 1)
            : picker.getMonth() + 1
        ).toString();
        let D = picker.getDate().toString();
        let date = Y + "-" + M + "-" + D;
        this.date = date;
      } else {
        this.date = "";
      }
      this.loadData(1);
    },
  },
  mounted() {
    this.loadData(1);
  },
};
</script>

<style lang="scss">
  .take-total {
    .picker-box {
      position: relative;

      .date-picker {
        width: 140px;
        position: absolute;
        right: -4px;
        top: -4px;

        .el-input__inner {
          width: 140px;
          height: 28px;
        }

        .el-input__icon {
          line-height: 28px;
        }
      }
    }

    .el-card {
      border: 0;

      .el-card__header {
                background: #1777FF;
                color: #ffffff;
            }
    }

    .cell {
      font-size: 12px;
    }

    .el-pagination {
      margin-top: 20px;
    }
  }
</style>
